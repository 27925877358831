// import
import { useQuery } from "react-query";
import { TITLE } from "../../config";
import PageMeta from "../../functions/PageMeta/PageMeta";

// assets
import style from "./Home.module.scss";

import Hero from "../../assets/images/home-hero.jpg";
import IcoInstagram from "../../assets/images/ico-instagram.png";

// Home
function Home() {
  const { isLoading, data, error } = useQuery("fetchList", async () => {
    return await fetch(
      `https://graph.instagram.com/me/media?fields=id,caption,permalink,media_type,media_url,thumbnail_url&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`
    ).then((res) => res.json());
  });

  return (
    <>
      <PageMeta where={location} />

      <main id={style.home}>
        <div className="container">
          <img
            className={style.hero}
            src={Hero}
            alt=""
            width="960"
            height="500"
            aria-hidden="true"
          />

          <div className={style.textbox}>
            <strong className={style.text}>나를 비추는 거울</strong>
            <span className="common-headline">{TITLE}</span>

            <p className={style.subtext}>
              나의 몸과 마음 상태를 바라보고 나를 사랑하는 방법을 배워가요.
            </p>
          </div>

          {!error && !isLoading && (
            <div className={style["instagram-feed"]}>
              <a
                href="https://instagram.com/ayna_yogapila"
                className={style.label}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={IcoInstagram}
                  alt=""
                  aria-hidden="true"
                  title="아이나 요가&필라테스 공식 인스타그램 새창열기"
                />
                ayna_yogapila
              </a>

              <ul role="list">
                {data.data.map((item, index) => {
                  const thumbnail =
                    item.media_type === "VIDEO"
                      ? item.thumbnail_url
                      : item.media_url;

                  return (
                    index < 8 && (
                      <li key={index} role="listitem">
                        <a
                          href={item.permalink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={thumbnail}
                            alt={`${TITLE} - Instagram ${index + 1}`}
                            width="234"
                            height="234"
                            loading="lazy"
                          />

                          <div className={style.caption}>
                            <span>{item.caption}</span>
                          </div>
                        </a>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

export default Home;
